import { AnimatedText } from '@/components/animated-text';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawType } from '@/constants/draw/draw-types';
import { Draw, DrawAttendee } from '@/pages/draw/utils/interfaces';
import { Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import copy from 'copy-to-clipboard';
import { motion } from 'framer-motion';
import { LucideMessageCircle, LucideMessageSquareShare, LucideMoreHorizontal, LucidePhone } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import AttendeeQuestionsResponse from '../attendee-questions-response';

export default function Attendees({ Draw, Attendees }: { Draw: Draw; Attendees: DrawAttendee[] }) {
	const modalSeeResponse = useDisclosure();

	const handleRedirect = (href: string) => {
		const newTab = window.open(href, '_blank');
		if (newTab) {
			newTab.focus();
		}
		return null;
	};

	const [contentModalSeeResponse, setContentModalSeeResponse] = useState<DrawAttendee | null>(null);
	const handleOpenModalSeeResponse = (attendee: DrawAttendee) => {
		setContentModalSeeResponse(attendee);
		return modalSeeResponse.onOpen();
	};

	function renderUserResponse(attendee: DrawAttendee) {
		if (!attendee) return null;
		if (Draw.type === DrawType.questions && attendee.hits) {
			return (
				<ModalBody className="space-y-2">
					<AnimatedText
						texts={['Acertou', attendee.hits === 0 ? 'nenhuma' : <h1 className="text-primary">{attendee.hits}</h1>]}
						className="text-xl font-normal"
					/>
					<ScrollArea className="h-[450px] pr-3">
						<AttendeeQuestionsResponse Attendee={attendee} />
					</ScrollArea>
				</ModalBody>
			);
		}
		if (Draw.type === DrawType.text && attendee.text) {
			return (
				<ModalBody>
					<motion.div variants={SlideFromTop} initial="initial" animate="animate">
						<Textarea value={attendee.text!} readOnly className="min-h-36" />
					</motion.div>
				</ModalBody>
			);
		}
		if (Draw.type === DrawType.list) return null;
	}

	if (!Attendees) return null;

	if (Draw.type === DrawType.list) {
		return (
			<motion.div variants={SlideInFromLeft} initial="initial" animate="animate" className="flex flex-col gap-4">
				<motion.h1 variants={SlideInFromLeftItem} className="text-xl">
					Sua lista:
				</motion.h1>
				<motion.div variants={SlideInFromLeftItem}>
					<Textarea className="min-h-36" value={Draw.list?.list.join(', ')} readOnly />
				</motion.div>
				<motion.h1 variants={SlideInFromLeftItem} className="text-xl">
					Os <code className="text-primary">Ganhadores</code> foram:
				</motion.h1>
				<motion.div variants={SlideFromTop} className="space-y-2">
					{Draw.list?.winners &&
						Draw.list.winners.map((attendee, index) => (
							<motion.div key={index} variants={SlideFromTopItem} className="flex h-12 w-full items-center gap-2 rounded-md bg-muted p-4">
								<h1 className="line-clamp-1 text-lg capitalize">{attendee}</h1>
							</motion.div>
						))}
				</motion.div>
			</motion.div>
		);
	}

	if (Draw.type === DrawType.text || Draw.type === DrawType.questions) {
		return (
			<motion.div
				onClick={(e: any) => e.stopPropagation()}
				variants={SlideFromTop}
				initial="initial"
				animate="animate"
				className="w-full flex-col gap-2 space-y-2"
			>
				<motion.h1 variants={SlideFromTopItem} className="text-xl text-primary">
					{Attendees.length} participantes!
				</motion.h1>

				{Attendees.map((attendee, index) => {
					return (
						<motion.div
							key={index}
							variants={SlideFromTopItem}
							className="flex w-full flex-col items-center justify-between gap-2 overflow-hidden rounded-md border border-muted p-2 sm:h-12 sm:flex-row"
						>
							<h1 className="line-clamp-1 text-lg capitalize">
								{attendee.person?.name}
								{Draw.type === DrawType.questions && (
									<span className="font-normal text-muted-foreground">
										- Acertou <code className="font-normal">{attendee.hits}</code>
									</span>
								)}
							</h1>

							<DropdownMenu>
								<DropdownMenuTrigger>
									<Button size={'icon'} variant={'outline'}>
										<LucideMoreHorizontal size={18} />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent>
									<DropdownMenuGroup>
										<DropdownMenuItem
											className="flex items-center justify-between gap-2"
											onClick={() => {
												handleRedirect(`https://wa.me/+55${attendee.person.phone}`);
											}}
										>
											Conversar <LucideMessageSquareShare size={16} />
										</DropdownMenuItem>
										<DropdownMenuItem
											className="flex items-center justify-between gap-2"
											onClick={() => {
												copy(`+55${attendee.person.phone}`);
												toast.success(`Número de ${attendee.person.name} - ${attendee.person.phone} copiado com sucesso!`);
											}}
										>
											Copiar número <LucidePhone size={16} />
										</DropdownMenuItem>
										<DropdownMenuItem
											className="flex items-center justify-between gap-2"
											onClick={() => handleOpenModalSeeResponse(attendee)}
										>
											Ver resposta <LucideMessageCircle size={16} />
										</DropdownMenuItem>
									</DropdownMenuGroup>
								</DropdownMenuContent>
							</DropdownMenu>
						</motion.div>
					);
				})}
				<Modal
					backdrop="opaque"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/30 to-background/50 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalSeeResponse.isOpen}
					onOpenChange={modalSeeResponse.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader>
							<h1 className="mx-auto text-2xl font-semibold capitalize">{contentModalSeeResponse?.person?.name}</h1>
						</ModalHeader>
						{renderUserResponse(contentModalSeeResponse!)}
					</ModalContent>
				</Modal>
			</motion.div>
		);
	}
}
